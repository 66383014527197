<template>
  <page-layout title="Payment">
    <div v-if="!!paymentData" class="h-screen -mt-20 flex justify-center items-center">
      <confirmation
        v-if="success"
        status="success"
        title="Payment successful"
        description="You’re all set! You will receive an invoice by email shortly."
        action="View session confirmation"
        @actionClick="
          this.$router.replace(
            `/sessions/${session}`
          )
        "
        image="https://i.ibb.co/6ZKRX47/Vector.png"
      />
      <confirmation
        v-else
        title="Payment declined"
        status="failure"
        action="try again"
        description="This transaction didn’t go through. You can try again in a few seconds"
        image="https://i.ibb.co/rySK4vw/Component-1.png"
        @actionClick="payWithPaymob(session)"
      />
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "../components/base/pageLayout.vue";

import Confirmation from "../components/layouts/confirmation.vue";
import { queryToJSON } from "../helpers/parser";
import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  created() {
    this.payment = queryToJSON(window.location.search);
    if(!this.payment.success) {
      this.paymentData = this.payment
      this.success = false
    }
    else {
      this.success = true
      this.paymentData = {}
      
      axios.post("https://api.tutoruu.com/api/pay", { payment: this.payment }).then((resp) => {
        if (resp.data.success) this.success = true
          axios
            .get(`https://api.tutoruu.com/api/pay/status/${this.payment.order}`)
            .then(res => this.paymentData = res.data);
      });
    }
  },
  computed: {
    ...mapState(["user"]),
    session () {
      return this.paymentData?.session?._id || window.localStorage.getItem("session_previewed_id");
    }
  },
  methods: {
    ...mapActions(["payWithPaymob"]),
  },
  data() {
    return {
      payment: {},
      paymentData: null,
      success: false,
    };
  },
  components: { Confirmation, PageLayout },
};
</script>

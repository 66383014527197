<template>
  <div
    class="w-full h-auto mx-auto my-auto flex flex-col items-center justify-center"
  >
    <img class="mx-auto w-80 md:w-96 block" :src="image" />
    <div class="pb-8 px-4">
      <p
        class="px-6 text-center text-xl font-bold pb-2"
        :class="{
          'text-orange': status == 'success',
          'text-red-500': status == 'failure',
          'text-shark': status != 'success' && status != 'failure',
        }"
      >
        {{ title }}
      </p>
      <p class="font-extralight text-center px-5 pb-8">
        {{ description }}
      </p>
      <Button full type="primary" :text="action" @click="this.$emit('actionClick')" />
    </div>
  </div>
</template>

<script>
import Button from '../button.vue';
export default {
  components: { Button },
  props: {
    image: String,
    title: String,
    description: String,
    action: String,
    status: String,
  },
  emits: ["actionClick"],
};
</script>
